import PropTypes from "prop-types";
import classNames from "classnames";
import classes from "./textArea.module.css"; // Assuming you have some CSS file for basic styles

const Textarea = ({
  value,
  placeholder,
  onChange,
  disabled = false,
  fullWidth = false,
  size = "md",
  error = false,
  rows = 3,
  className = "",
  ...rest
}) => {
  const classList = classNames(
    classes["txa"],
    classes[`txa-${size}`],
    classes[
      {
        "txa-full": fullWidth,
        "txa-dis": disabled,
        "txa-err": error,
      }
    ],
    className
  );

  return (
    <textarea
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      rows={rows}
      className={classList}
      {...rest}
    />
  );
};

Textarea.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  error: PropTypes.bool,
  rows: PropTypes.number,
  className: PropTypes.string,
};

export default Textarea;
