import classNames from "classnames";
import classes from "./industrialCopilot.module.css";
import Image from "src/components/Image";
import Typography from "src/components/Typography";

export const IndustrialCopilot = () => {
  return (
    <div className={classNames("container", classes.indusrialAiContainer)}>
      <Image
        src="v1719418539/copilot.jpg"
        alt=""
        className={classes.copilotImage}
      />
      <div>
        <Typography variant="h2" color="#171B24">
          Industrial AI Co-Pilot
        </Typography>
        <br />
        <Typography>
          A first-in-class solution designed to streamline and optimize the
          handling of engineering goods. It ensures precision and quality while
          offering 24x7 customer support, providing real-time assistance and
          enhancing the overall experience for our buyers.
        </Typography>
      </div>
    </div>
  );
};
