import Button from "src/components/Button";
import classes from "./email.module.css";
import Typography from "src/components/Typography";
import useMobile from "src/utils/hooks/useMobile";
export default function EmailInput({ setEmail, email }) {
  const isMobile = useMobile();

  return (
    <div>
      <div className={classes.emailContainer}>
        <input
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder="Enter your email"
        />
        {!isMobile && (
          <Typography
            variant="subtitle2"
            color="white"
            component="a"
            href="#contactUs"
          >
            <Button className={classes.reachOutButton}>Reach out</Button>
          </Typography>
        )}
      </div>
      {isMobile && (
        <Typography
          variant="subtitle2"
          color="white"
          component="a"
          href="#contactUs"
        >
          <Button className={classes.reachOutButton}>Reach out</Button>
        </Typography>
      )}
    </div>
  );
}
