import Typography from "src/components/Typography";
import classes from "./carbonNeutral.module.css";
import sample from "../../../assets/home/manufacturers.png";
import classNames from "classnames";
import Button from "src/components/Button";
import { memo } from "react";
import Image from "src/components/Image";
export const CarbonNeutral = memo(() => {
  return (
    <div>
      <div className={classNames(classes.carbonContainer, "container")}>
        <div>
          <Typography color="nav" variant="h5">
            -CARBON NEUTRAL
          </Typography>
          <br />
          <Typography variant="h2" className={classes.heading}>
            Manage your carbon credits with best in class carbon footprint
            tracker
          </Typography>
          <br />
          <Typography variant="h6" component="a" href="#contactUs">
            <Button variant="secondary" className={classes.reachOut}>
              Reach out
            </Button>
          </Typography>
        </div>
        <Image
          src="v1719418546/Carbon.jpg"
          alt=""
          className={classes.carbonImage}
        />
      </div>
      <div className={classNames(classes.carbonContainer, "container")}>
        <div>
          <Typography color="nav" variant="h5">
            -ETHICAL MANUFACTURING
          </Typography>
          <br />
          <Typography variant="h2" className={classes.heading}>
            Best in class working conditions & worker safety
          </Typography>
          <br />
          <Typography variant="h6" component="a" href="#contactUs">
            <Button variant="secondary" className={classes.reachOut}>
              Reach out
            </Button>
          </Typography>
        </div>
        <Image
          src="v1719418545/front-view-protective-glasses-with-hard-hat-headphones.jpg"
          alt=""
          className={classes.carbonImage}
        />
      </div>
    </div>
  );
});
