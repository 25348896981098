import PropTypes from "prop-types";
import classNames from "classnames";
import classes from "./button.module.css"; // Assuming you have some CSS file for basic styles

const Button = ({
  variant = "contained",
  color = "default",
  size = "medium",
  disabled = false,
  fullWidth = false,
  className = "",
  children,
  onClick,
  ...rest
}) => {
  const classList = classNames(
    classes["btn"],
    classes[`btn-${variant}`],
    classes[`btn-${color}`],
    classes[`btn-${size}`],
    classes[
      {
        "btn-disabled": disabled,
        "btn-full": fullWidth,
      }
    ],
    className
  );

  return (
    <button
      className={classList}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  color: PropTypes.oneOf(["default", "primary", "secondary", "error"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default Button;
