import Typography from "src/components/Typography";
import classes from "./contactUs.module.css";
import classNames from "classnames";
import Input from "src/components/Input";
import Textarea from "src/components/TextArea";
import Button from "src/components/Button";
import { postData } from "src/dataService/index";
import { useEffect, useState } from "react";
const initialData = {
  name: "",
  email: "",
  phoneNo: "",
  organization: "",
  country: "",
  message: "",
};
export const ContactUs = ({ email }) => {
  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await postData("v1/users/leads", formData);
      setFormData(initialData);
      setLoading(false);
      setFormSubmitted(true);
      setTimeout(() => {
        setFormSubmitted(false);
      }, 5000);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  const handleOnChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    if (email) {
      setFormData({ ...formData, email: email });
    }
  }, [email]);

  return (
    <footer className={classNames("container", classes.contactUsContainer)}>
      <div>
        <Typography className={classes.heading}>Let’s work together</Typography>

        <Typography
          variant="caption"
          style={{ fontWeight: "600" }}
          color="primary"
        >
          Email address
        </Typography>
        <Typography variant="h5" color="primary">
          info@ikag.ai
        </Typography>
        <br />
        <Typography
          variant="caption"
          style={{ fontWeight: "600" }}
          color="primary"
        >
          PHONE
        </Typography>
        <Typography variant="h5" color="primary">
          +91-9555157134{" "}
        </Typography>
        <Typography variant="h5" color="primary">
          +91-9958689853
        </Typography>
        <br />
        <Typography
          variant="caption"
          style={{ fontWeight: "600" }}
          color="primary"
        >
          Head office
        </Typography>
        <Typography variant="h5" color="primary">
          New Delhi, India
        </Typography>
      </div>
      <form className={classes.form} id="contactUs" onSubmit={handleSubmit}>
        <Input
          size="large"
          className={classes.input}
          placeholder="Name"
          name="name"
          onChange={handleOnChange}
          value={formData.name}
          required
        />
        <Input
          size="large"
          className={classes.input}
          placeholder="Email"
          name="email"
          onChange={handleOnChange}
          value={formData.email}
          type="email"
          pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$"
          title="Invalid email address"
          required
        />
        <Input
          size="large"
          className={classes.input}
          onChange={handleOnChange}
          value={formData.phoneNo}
          name="phoneNo"
          type="tel"
          placeholder="Phone number"
        />
        <Input
          size="large"
          className={classes.input}
          placeholder="Country"
          value={formData.country}
          name="country"
          onChange={handleOnChange}
        />
        <Input
          size="large"
          className={classes.input}
          placeholder="Organization"
          name="organization"
          value={formData.organization}
          onChange={handleOnChange}
        />
        <Textarea
          className={classes.input}
          placeholder="Type your message here"
          name="message"
          onChange={handleOnChange}
          rows={8}
          value={formData.message}
        />
        <div className={classes.submitContainer}>
          <Typography variant="subtitle2" color="white">
            <Button
              className={classes.submitButton}
              type="submit"
              disabled={loading}
            >
              Submit
            </Button>
          </Typography>
          {formSubmitted && <span>Details Submitted</span>}
        </div>
      </form>
    </footer>
  );
};
