import Manufacturers from "src/assets/home/manufacturers.png";

export default [
  {
    heading: "Aerospace",
    image: "v1719418542/Aerospace.jpg",
  },
  {
    heading: "Defense",
    image: "v1719418539/Defence_industry.jpg",
  },
  {
    heading: "Medical Devices",
    image: "v1719418539/Medical.jpg",
  },
  {
    heading: "Automobile",
    image: "v1719418542/automobile.jpg",
  },
  {
    heading: "Food & Beverage",
    image: "v1719418544/Food_Beverage.jpg",
  },
  {
    heading: "Material Handling Equipment",
    image: "v1719418541/material_handling.jpg",
  },
  {
    heading: "Construction",
    image: "v1719418541/construction.jpg",
  },
  {
    heading: "Have anything else in mind ? Reach out to us !",
    image: "v1719418538/others.jpg",
  },
];
