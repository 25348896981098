export default [
  {
    heading: "On-Demand Production Status",
    image: "v1719418545/On-Demand_Production_Status.jpg",
  },
  {
    heading: "AI-Powered quotes",
    image: "v1719418546/AI-Powered_quotes.jpg",
  },
  {
    heading: "IOT integrated Products",
    image: "v1719418545/IOT.jpg",
  },
  {
    heading: "AI assisted Quality Assurance",
    image: "v1719418546/AI.jpg",
  },
];
