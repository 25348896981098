import Manufacturers from "src/assets/home/manufacturers.png";

export default [
  {
    heading: "Precision Engineering",
    desc: "Precision engineering for engineering goods ensures exacting accuracy and superior quality in every component. ",
    image: "v1719418544/Precision.jpg",
  },
  {
    heading: "CNC Machining",
    desc: "CNC machining offers precision, efficiency, and reliability in crafting high-quality engineering goods. ",
    image: "v1719418541/cnc.jpg",
  },
  {
    heading: "Fabrication ",
    desc: "The fabrication process transforms any raw material into precise, high-quality engineering goods.",
    image: "v1719418542/Fabrication.jpg",
  },
  {
    heading: "Injection Molding",
    desc: "Injection molding crafts any raw material into precise, durable engineering goods with exceptional quality.",
    image: "v1719418542/InjectionMoulding.jpg",
  },
  {
    heading: "Robotic Welding",
    desc: "Robotic welding automates precise, consistent welds, enhancing the quality and efficiency of engineering goods production. ",
    image: "v1719418545/Robotic_welding.jpg",
  },
  {
    heading: "Composite Manufacturing",
    desc: "Utilises carbon fiber , fiber glass,  Kevlar  etc to create high performance  lightweight, high-strength engineering goods  ",
    image: "v1719418542/composites.jpg",
  },
];
