import Manufacturers from "src/assets/home/manufacturers.png";

export default [
  {
    heading: "Speed & Value",
    image: "v1719418548/speed_value.jpg",
  },
  {
    heading: "Superior Quality",
    image: "v1719418547/Superior_quality.jpg",
  },
  {
    heading: "Reliable Services",
    image: "v1719418546/reliable.jpg",
  },
  {
    heading: "Warranty & Protection",
    image: "v1719418547/Warranty.jpg",
  },
];
