import Typography from "src/components/Typography";
import classes from "./capabilities.module.css";
import capabilitiesData from "./capabilities.data";
import { UseCase } from "src/components/UseCase";
import { memo } from "react";
import Button from "src/components/Button";

export const Capabilities = memo(() => {
  return (
    <div className="container" id="capabilities">
      <div className={classes.headingContainer}>
        <Typography variant="h2" color="secondary">
          Capabilities
        </Typography>
        <Typography color="white" component="a" href="#contactUs">
          <Button className={classes.reactOut}>Reach out</Button>
        </Typography>
      </div>
      <div className={classes.capabilities}>
        {capabilitiesData.map(({ heading, image, desc }, index) => (
          <UseCase heading={heading} image={image} desc={desc} key={index} />
        ))}
      </div>
    </div>
  );
});
