import Typography from "src/components/Typography";
import classes from "./industries.module.css";
import industriesData from "./industries.data";
import { UseCase } from "src/components/UseCase";
import Button from "src/components/Button";
import { memo } from "react";
export const Industries = memo(() => {
  return (
    <div className="container" id="industries">
      <div className={classes.headingContainer}>
        <Typography variant="h2" color="secondary">
          Industries
        </Typography>
        <Typography color="white" component="a" href="#contactUs">
          <Button className={classes.reactOut}>Reach out</Button>
        </Typography>
      </div>
      <div className={classes.capabilities}>
        {industriesData.map(({ heading, image }, index) => (
          <UseCase heading={heading} image={image} key={index} />
        ))}
      </div>
    </div>
  );
});
