import Typography from "src/components/Typography";
import classes from "./accredition.module.css";
import Image from "src/components/Image";
import accreditionData from "./accredition.data";
import { memo } from "react";
export const Accreditation = memo(() => {
  return (
    <div className="container" id="accreditation">
      <Typography
        color="nav"
        variant="h5"
        className={classes.accreditationHeading}
      >
        ACCREDITATIONS
      </Typography>
      <div className={classes.imageContainer}>
        {accreditionData.map((item) => (
          <Image src={item} height={75} key={item} />
        ))}
      </div>
    </div>
  );
});
