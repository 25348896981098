import PropTypes from "prop-types";
import classNames from "classnames";
import classes from "./typography.module.css"; // Assuming you have some CSS file for basic styles

const Typography = ({
  variant = "body1",
  component: Component = "p",
  color = "default",
  align = "left",
  gutterBottom = false,
  noWrap = false,
  className = "",
  children,
  ...rest
}) => {
  const classList = classNames(
    classes[`ty-${variant}`],
    classes[`ty-${color}`],
    classes[`ty-${align}`],
    classes[
      {
        "ty-gb": gutterBottom,
        "ty-nw": noWrap,
      }
    ],
    className
  );

  return (
    <Component className={classList} {...rest}>
      {children}
    </Component>
  );
};

Typography.propTypes = {
  variant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "caption",
    "button",
    "overline",
  ]),
  component: PropTypes.elementType,
  color: PropTypes.oneOf([
    "white",
    "default",
    "primary",
    "secondary",
    "nav",
    "error",
  ]),
  align: PropTypes.oneOf(["left", "center", "right", "justify"]),
  gutterBottom: PropTypes.bool,
  noWrap: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Typography;
