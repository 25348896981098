import PropTypes from "prop-types";
import classNames from "classnames";
import classes from "./input.module.css"; // Assuming you have some CSS file for basic styles

const Input = ({
  type = "text",
  value,
  placeholder,
  onChange,
  disabled = false,
  fullWidth = false,
  size = "medium",
  error = false,
  className = "",
  ...rest
}) => {
  const classList = classNames(
    classes["input"],
    classes[`input-${size}`],
    classes[
      {
        "input-full": fullWidth,
        "input-disabled": disabled,
        "input-error": error,
      }
    ],
    className
  );

  return (
    <input
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      className={classList}
      {...rest}
    />
  );
};

Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  className: PropTypes.string,
};

export default Input;
