import PropTypes from "prop-types";
import { CDN_URL } from "src/utils/constants";

const Image = ({
  src,
  alt,
  width,
  height,
  className,
  onClick,
  widths = [256, 512, 768, 1024, 1280],
  loading = "lazy",
  formCDN = true,
  ...restProps
}) => {
  const handleError = (e) => {
    e.currentTarget.src = "fallback-image-url";
  };

  const generateSrcSet = (widths) => {
    return widths
      .map((width) => {
        return `${CDN_URL}/c_scale,w_${width}/${src} ${width}w`;
      })
      .join(", ");
  };

  return (
    <img
      loading={loading}
      src={formCDN ? CDN_URL + src : src}
      alt={alt}
      width={width}
      height={height}
      srcSet={formCDN ? generateSrcSet(widths) : ""}
      sizes="(min-width: 50em) 50em, 100vw"
      className={`${className || ""}`}
      onClick={onClick}
      onError={handleError}
      {...restProps}
    />
  );
};
export default Image;

Image.propTypes = {
  loading: PropTypes.oneOf(["lazy", "eager"]),
  formCDN: PropTypes.bool,
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  widths: PropTypes.array,
};
