import classNames from "classnames";
import Typography from "src/components/Typography";
import classes from "./aboutus.module.css";
export const AboutUs = () => {
  return (
    <div
      className={classNames("container", classes.aboutUsContainer)}
      id="aboutUs"
    >
      <Typography color="nav" variant="h5">
        ABOUT US
      </Typography>
      <br />
      <Typography color="secondary" variant="h2">
        We are a team of experts streamlining the procurement of engineering
        goods for businesses globally
      </Typography>
      <br />
    </div>
  );
};
