import Typography from "src/components/Typography";
import classes from "./teamMembers.module.css";
import { memo } from "react";
export const TeamMembers = memo(() => {
  return (
    <div className="container">
      <div className={classes.teamContainer}>
        <div>
          <Typography
            variant="h2"
            color="secondary"
            className={classes.heading}
          >
            Sanchit Singhal
          </Typography>
          <Typography
            component="span"
            variant="h2"
            className={classes.subHeading}
          >
            Co-Founder
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.linkedIn}
            component="a"
            href="https://www.linkedin.com/in/sanchit-singhal-a7324558/"
            target="_blank"
          >
            Linkedin
          </Typography>
        </div>
        <div>
          <Typography variant="h2" color="secondary">
            Prasoon Priyank
          </Typography>
          <Typography
            component="span"
            variant="h2"
            className={classes.subHeading}
          >
            Co-Founder
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.linkedIn}
            component="a"
            href="https://www.linkedin.com/in/prasoon-priyank/"
            target="_blank"
          >
            Linkedin
          </Typography>
        </div>
        <div>
          <Typography variant="h2" color="secondary">
            Rishav Sinha
          </Typography>
          <Typography
            component="span"
            variant="h2"
            className={classes.subHeading}
          >
            Co-Founder
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.linkedIn}
            component="a"
            href="https://www.linkedin.com/in/rishav-sinha-7236904a/"
            target="_blank"
          >
            Linkedin
          </Typography>
        </div>
      </div>
    </div>
  );
});
