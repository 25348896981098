import classes from "./technnologyPlatfrom.module.css";
import Typography from "src/components/Typography";
import Button from "src/components/Button";
import { UseCase } from "src/components/UseCase";
import technologyData from "./technology.data";
export const TechnologyPlatform = () => {
  return (
    <div className="container">
      <div className={classes.headingContainer}>
        <Typography variant="h3" color="secondary" style={{ color: "#4A3AFF" }}>
          Technology Platform
        </Typography>
        <Button className={classes.reactOut}>
          <Typography color="white" component="a" href="#contactUs">
            Reach out
          </Typography>
        </Button>
      </div>
      <div className={classes.capabilities}>
        {technologyData.map(({ heading, image }, index) => (
          <UseCase heading={heading} image={image} key={index} />
        ))}
      </div>
    </div>
  );
};
