import { Header } from "./Header";
import { ChooseUs } from "./ChooseUs";
import { CarbonNeutral } from "./CarbonNeutral";
import { Capabilities } from "./Capabilities";
import { Industries } from "./Industries";
import { AboutUs } from "./AboutUs";
import { ContactUs } from "./ContactUs";
import { Advantages } from "./Advantages/indes";
import { TechnologyPlatform } from "./TechnologyPlatform";
import { Accreditation } from "./Accreditation";
import { TeamMembers } from "./TeamMembers";
import { useState } from "react";
import { IndustrialCopilot } from "./IndustrialCoPilot";

export const Home = () => {
  const [email, setEmail] = useState("");

  return (
    <>
      <Header setEmail={setEmail} email={email} />
      <ChooseUs />
      <Advantages />
      <TechnologyPlatform />
      <IndustrialCopilot />
      <CarbonNeutral />
      <Capabilities />
      <Industries />
      <Accreditation />
      <AboutUs />
      <TeamMembers />
      <ContactUs email={email} />
    </>
  );
};
