export default [
  "v1719418543/ISO.png",
  "v1719418540/iso9.png",
  "v1719418540/iso8.png",
  "v1719418539/iso7.png",
  "v1719418539/iso10.png",
  "v1719418538/iso4.png",
  "v1719418538/ISO5.png",
  "v1719418537/iso6.jpg",
  "v1719418536/ISO3.png",
  "v1719418536/iso2.png",
];
