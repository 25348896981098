export default [
  {
    heading: "One stop Shop",
    desc: "Infinite products for  cross border B2B sourcing ",
  },
  {
    heading: "Highest Quality",
    desc: "Meets Global standards & Rigorous Quality testing ",
  },
  {
    heading: "Fast Response",
    desc: "24-hour quotes, & 12 hr response for first contact",
  },
  {
    heading: "Tech Integrated",
    desc: "State of art manufacturing technology",
  },
  {
    heading: "24x7 Support",
    desc: "24X7 assistance & AI co-pilot for query resolution",
  },
  {
    heading: "Privacy & Copyrights",
    desc: " Robust protection of designs and blueprints",
  },
  {
    heading: "Cost Savings",
    desc: " AI-driven competitive rates",
  },
  {
    heading: "Fast Procurement",
    desc: "Doorstep - air delivery, installation & commissioning",
  },
  {
    heading: "Raw Materials",
    desc: "Top notch raw materials used for manufacturing",
  },
  {
    heading: "Sustainable",
    desc: " Best in Class Sustainable sourcing & Packaging",
  },
  {
    heading: "Integrated Warranty",
    desc: "Comprehensive Warranty",
  },
  {
    heading: "Ease of Payments",
    desc: " Simplified payment plans & solutions",
  },
];
