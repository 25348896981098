import Image from "../Image";
import Typography from "../Typography";
import classes from "./usecase.module.css";
import PropTypes from "prop-types";

export const UseCase = ({ heading, image, desc = "" }) => {
  return (
    <div className={classes.useCaseContainer}>
      <Image src={image} alt={heading} className={classes.useCaseImge} />
      <Typography variant="h4" className={classes.heading}>
        {heading}
      </Typography>
      {desc && (
        <Typography className={classes.description} variant="body-2">
          {desc}
        </Typography>
      )}
    </div>
  );
};
UseCase.propTypes = {
  heading: PropTypes.string,
  image: PropTypes.string,
  desc: PropTypes.string,
};
