import Typography from "../Typography";
import PropTypes from "prop-types";
import classes from "./points.module.css";

export const Points = ({ heading, desc }) => {
  return (
    <div>
      <Typography variant="h5">{heading}</Typography>
      <Typography
        variant="subtitle1"
        color="primary"
        className={classes.pointsDescription}
      >
        {desc}
      </Typography>
    </div>
  );
};

Points.propTypes = {
  heading: PropTypes.string,
  desc: PropTypes.string,
};
