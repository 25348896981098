import Typography from "src/components/Typography";
import classes from "./header.module.css";
import Button from "../../../components/Button";
export const NavBar = () => {
  return (
    <nav className={classes.navbar}>
      <Typography variant="h1" color="secondary" component="a" href="/">
        IKAG
      </Typography>
      <div className={classes.navLinksContainer}>
        {/* <Typography color="nav" variant="subtitle2" component="a">
          Solution
        </Typography> */}
        <Typography
          color="nav"
          variant="subtitle2"
          component="a"
          href="#capabilities"
        >
          Capabilities
        </Typography>
        <Typography
          color="nav"
          variant="subtitle2"
          component="a"
          href="#industries"
        >
          Industries
        </Typography>
        {/* <Typography color="nav" variant="subtitle2" component="a" href="">
          Industrial AI
        </Typography> */}
        <Typography
          color="nav"
          variant="subtitle2"
          component="a"
          href="#accreditation"
        >
          Accreditations
        </Typography>

        <Typography
          color="nav"
          variant="subtitle2"
          component="a"
          href="#aboutUs"
        >
          About Us
        </Typography>
        <Typography
          variant="subtitle2"
          color="white"
          component="a"
          href="#contactUs"
        >
          <Button className={classes.reachButton}>Reach Out</Button>
        </Typography>
      </div>
    </nav>
  );
};
