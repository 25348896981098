import axios from "axios";

const api = axios.create({
  baseURL: "http://23.21.128.170:3000/", // Replace with your API base URL
  timeout: 10000, // Timeout after 10 seconds
  headers: {
    "Content-Type": "application/json",
  },
});

const apiCall = async (method, endpoint, data = null) => {
  try {
    const response = await api({
      method,
      url: endpoint,
      data,
    });
    return response.data;
  } catch (error) {
    console.error(`Error with ${method} request to ${endpoint}:`, error);
    throw error;
  }
};

export const fetchData = (endpoint) => apiCall("get", endpoint);
export const postData = (endpoint, data) => apiCall("post", endpoint, data);
export const updateData = (endpoint, data) => apiCall("put", endpoint, data);
export const deleteData = (endpoint) => apiCall("delete", endpoint);
