import classes from "./advantages.module.css";
import { Points } from "src/components/Points";
import points from "./advantages.data";
import Typography from "src/components/Typography";
import { memo } from "react";

export const Advantages = memo(() => {
  return (
    <div className="container">
      <Typography variant="h3" color="secondary" style={{ color: "4A3AFF" }}>
        IKAG Advantages
      </Typography>
      <div className={classes.pointsList}>
        {points.map(({ heading, desc }, index) => (
          <Points heading={heading} desc={desc} key={index} />
        ))}
      </div>
    </div>
  );
});
