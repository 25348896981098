import classes from "./header.module.css";
import BackgroundImage from "../../../assets/home/mainBackground.jpg";
import { NavBar } from "./NavBar";
import Typography from "src/components/Typography";
import EmailInput from "./EmailInput";
export const Header = ({ setEmail, email }) => {
  return (
    <div>
      <div className={classes.headerBackground}>
        <img src={BackgroundImage} className={classes.mainBackground} />
      </div>

      <div className={classes.overlapContainer}>
        <NavBar />
        <div className={classes.headingContainer}>
          <Typography variant="h1" align="center" color="secondary">
            Cross-Border Procurement of  Quality Engineering Goods
          </Typography>
          <br />
          <EmailInput setEmail={setEmail} email={email} />
        </div>
      </div>
    </div>
  );
};
