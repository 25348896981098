import Typography from "src/components/Typography";
import classes from "./chooseUs.module.css";
import chooseUsData from "./chooseUs.data";
import { UseCase } from "src/components/UseCase";
import classNames from "classnames";
import { memo } from "react";

export const ChooseUs = memo(() => {
  return (
    <section className={classes.chooseUsContainer}>
      <Typography
        variant="h2"
        color="secondary"
        className={classes.heading}
        component="h2"
      >
        Why choose us?
      </Typography>
      <div className={classes.chooseUs}>
        {chooseUsData.map(({ heading, image }, index) => (
          <UseCase heading={heading} image={image} key={index} />
        ))}
      </div>
    </section>
  );
});
